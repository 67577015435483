import {React, useEffect} from 'react'
import EImages from '../../components/Empresa_images/EImages'
import MV_section from '../../components/MV_section/MV_section'
import Parceiros from '../../components/Parceiros/Parceiros'
import './Empresa.css'

function Empresa() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="empresa">
                <div className="empresa-about-section">
                    <div className="about-wrapper">
                        <div className="about-title">COPLUSA</div>
                            <div className="about-content-wrapper">
                                <div className="about-leftp"><p>A Coplusa – Construção de Obras Públicas e Privadas Lda, é uma empresa de referência no mercado, procurando apresentar-se no mercado com uma imagem sólida, moderna, transmitindo fiabilidade aos seus colaboradores.</p></div>
                                <div className="divider"></div>
                                <div className="about-rightp"><p>O negócio da empresa, tem como principal actividade a construção civil na qual conta com uma larga experiência, onde obtemos uma enorme diversidade de obras executadas em vários pontos do país. Por detrás desta empresa está pessoal especializado e com vários anos de experiência, que dará sempre a melhor resposta a qualquer problema.</p><p>Dispondo de equipas constituídas por Engenheiros, Arquitetos, Técnicos e outros profissionais detentores de grande competência técnica, profissionalismo e experiência, a Coplusa, está vocacionada para prestar um serviço com elevado grau de especialização e qualidade.</p></div>
                            </div>
                        </div>
                    </div>
                    <EImages/>
                    <MV_section/>
                    <Parceiros/>
    </div>
  )
}

export default Empresa