import React from "react";
import "./Parceiros.css";
import { PLogo } from "./PLogo";

function Parceiros() {
  return (
    <div className='parceiros'>
      <div className='parceiros-img'>
        <div className='parceiros-texto-wrapper'>
          <p className='parceiros-texto'>
            A relação de confiança com os nossos parceiros é a base do nosso
            sucesso
          </p>
        </div>
      </div>
      <div className='parceiros-container'>
        <div className='parceiros-wrapper'>
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/equepon.JPG"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/isoplak.jpg"} />
          <PLogo
            url={process.env.PUBLIC_URL + "logos_parceiros/xbuilding.jpg"}
          />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/movadel.jpg"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/ibertec.jpg"} />
          <PLogo
            url={process.env.PUBLIC_URL + "logos_parceiros/tondelclima.png"}
          />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/WH.png"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/floema.png"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/bc.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/splash.png"} />
          <PLogo
            url={process.env.PUBLIC_URL + "logos_parceiros/dairotir.png"}
          />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/planb.png"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/macom.svg"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/styra.jpeg"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/dvlx.png"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/vm.svg"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/tc.png"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/vendap.png"} />
          <PLogo
            url={process.env.PUBLIC_URL + "logos_parceiros/forjante.jpg"}
          />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/lunelda.png"} />
          <PLogo
            url={process.env.PUBLIC_URL + "logos_parceiros/bestclean.jpg"}
          />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/engex.png"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/abanho.jpg"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/alcea.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/clf.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/cw.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/dig.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/mcm.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/nsis.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/ntagus.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/pa.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/prt.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/smec.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/sotg.jfif"} />
          <PLogo url={process.env.PUBLIC_URL + "logos_parceiros/valccc.jfif"} />
        </div>
      </div>
    </div>
  );
}

export default Parceiros;
