import './PLogo.css'
import React from 'react';

export const PLogo = ({
    children
    ,url,
}) => {
    return(
        <div className="plogo" >
            <img src={url} alt=""/>
        </div>
    );
};
